<template>
  <div class="right-content">
    <CustHeader @change="search" @search="search" :searchHint="searchHint"></CustHeader>
    <Tabs v-model="activedTab" style="width: 100%" @on-click="tabChange">
      <TabPane label="待签收" name="PENDING_SIGN" />
      <TabPane label="签署中" name="SIGNING" />
      <TabPane label="已签收" name="SIGNED" />
    </Tabs>
    <Button type="primary":disabled="selectUnSign.length < 1" v-if="activedTab === 'PENDING_SIGN'" @click="gotoSign">批量签收</Button>
    <Table style="width: 100%" :columns="activedTab === 'PENDING_SIGN' ? pendingSignColumns : otherColumns" :data="signReceiptList" @on-selection-change="changeSelect"></Table>
    <div style="width: 100%; padding-top: 12px">
      <Pagination :total="total"  :page-size-opts="[10, 20, 50, 100]" :size="reqParams.pageSize" :num="reqParams.pageNum" @change="pageChange" />
    </div>
  </div>
</template>
<script>
import { getMyCustomerInfoAPI } from '@/common/service'
import ComModal from '@/components/ComModal.vue'
import CustHeader from '../receiptFiling/components/CustHeader.vue'
import { getSignReceiptPage } from '@/common/service/consignee'
import {  verifyCustomer } from '@/common/service'
import { mapState } from 'vuex'
export default {
  name: 'electroncEvidence',
  computed: {
    ...mapState('consignee', ['customMdmNo'])
  },
  components: { ComModal, CustHeader },
  data() {
    return {
      searchHint:"请输入商家名称/商品名称/签收人/文件编号",
      reqParams: {
        condition: {
          clientCode: '',
          signStatus: 'PENDING_SIGN', //待签收:PENDING_SIGN，签署中：SIGNING、已签收:SIGNED
          searchKey: '' //商品名称 商家名称 签收人 文件编号
        },
        pageNum: 1,
        pageSize: 10
      },
      total:0,
      signReceiptList: [],
      selectUnSign: [],
      activedTab: 'PENDING_SIGN',
      pendingSignColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '文件编号',
          key: 'epodNo',
          width: '168px'
        },
        {
          title: '商家名称',
          key: 'orgName',
          width: '280px'
        },
        {
          title: '商品信息',
          key: 'consigneeName',
          render: (h, params) => {
            return h('div', [h('span', {}, `${params.row.orderNum}笔订单，${params.row.itemSortNum }种商品，共${params.row.itemNum || '0'}件`)])
          }
        },
        {
          title: '总金额',
          key: 'amount',
          width: '128px',
          align: 'right',
          render: (h, params) => {
            return h('div',{style:{"text-align":"right"}}, [h('span', {}, `${params.row.amount ? "¥"+String(params.row.amount).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''}`)])
          }
        },
        {
          title: '操作',
          width: '80px',
          render: (h, params) => {
            return h('div', [
              h(
                'a',
                {
                  class: 'oper-info',
                  on: {
                    click: () => {
                      this.gotoSingleSign(params.row)
                    }
                  }
                },
                `去签收`
              )
            ])
          }
        }
      ],
      otherColumns: [
        {
          title: '文件编号',
          key: 'epodNo',
          width: '168px'
        },
        {
          title: '商品信息',
          key: 'orderNum',
          width: '304px',
          render: (h, params) => {
            return h(
              'div',
              {
                class: 'column-info'
              },
              [
                h('span', {}, `${params.row.orgName}`),
                h('span', {}, `${params.row.orderNum}笔订单，${params.row.itemSortNum}种商品，${params.row.itemNum}件`),
                h('span', {}, `¥${params.row.amount}`)
              ]
            )
          }
        },
        {
          title: '签收时间',
          key: 'signTime',
          width: '171px'
        },
        {
          title: '签收人',
          key: 'orderNum',
          render: (h, params) => {
            return h(
              'div',
              {
                class: 'column-info'
              },
              [h('span', {}, `${params.row.signUser || '-'}`), h('span', { class: 'grey-info' }, `${params.row.signRemark || ''}`)]
            )
          }
        },
        {
          title: '操作',
          width: '80px',
          render: (h, params) => {
            return h('div', [
              h(
                'a',
                {
                  class: 'oper-info',
                  on: {
                    click: () => {
                      this.gotoDetail(params.row)
                    }
                  }
                },
                `详情`
              )
            ])
          }
        }
      ]
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    pageChange(e){
      this.reqParams.pageNum = e.pageNumber
      this.reqParams.pageSize =  e.pageSize
      this.loadData()
    },
    loadData() {
      this.reqParams.condition.clientCode = this.customMdmNo
      this.reqParams.condition.signStatus = this.activedTab
      if(!this.customMdmNo){
        this.signReceiptList = []
        this.total = 0
        return
      }
      getSignReceiptPage(this.reqParams).then(res => {
        if (res.code === 200) {
          this.signReceiptList = res.data.records
          this.total = res.data.total
        }
      })
    },
    tabChange(tab) {
      this.reqParams.pageNum = 1
      this.signReceiptList = []
      this.total = 0
      this.loadData()
    },
    search(searchKey) {
      this.reqParams.condition.searchKey = searchKey
      this.reqParams.pageNum = 1
      this.loadData()
    },
    changeSelect(detail) {
      this.selectUnSign = detail.map(item => item.epodId)
    },
    gotoSingleSign(dataSource) {
      this.verify([dataSource.epodId])
    },
    gotoDetail(dataSource){
      this.$router.push(`/reorderSign/detail/index?clientCode=${this.customMdmNo}&epodId=${dataSource.epodId}`)
    },
    gotoSign() {
      if (this.selectUnSign.length < 1) {
        return
      }
      if (this.selectUnSign.length > 20) {
        this.$message.error('最多选择20个文件')
        return
      }
      this.verify(this.selectUnSign)
    },
    async verify(epodIdArray) {
      const params = {
        clientCode: this.customMdmNo, // 客户编码
        orgNo: this.signReceiptList.filter(item=> epodIdArray.includes(item.epodId))?.map(item => item.orgNo)?.join(','), // 机构编码
        src: '3', // 1: 扫码 2: 消息提醒 3: 列表
      }
      const data = await verifyCustomer(params) // 校验收货人资质是否可用
      if (data.code === 200 && data.data) {
        this.$router.push(`/reorderSign/index?clientCode=${this.customMdmNo}&epodIds=${epodIdArray.join(',')}`)
      } else {
        this.$Modal.info({
          title: '签收提示',
          content: `${data.message}`,
          okText: '我知道了'
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.right-content {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: 8px;
  background: #fff;
}

:deep(tbody .ivu-table-cell) {
  color: var(--font_black-1, rgba(0, 0, 0, 0.9));

  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

:deep(thead .ivu-table-cell) {
  color: var(--font_black-1, rgba(0, 0, 0, 0.9));

  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

:deep(.tag) {
  padding: 0px 4px;
  align-items: center;
  gap: 4px;
  justify-content: center;
}

:deep(.un_open_auth) {
  border-radius: 4px;
  border: 1px solid var(--error-3, #fbaca3);
  background: var(--error-1, #ffece8);

  color: var(--error-6, #f53f3f);

  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

:deep(.un_auth) {
  border-radius: 4px;
  border: 1px solid var(--brand-3, #fdda9f);
  background: var(--brand-1, #fff8e8);

  color: var(--brand-7, #e67d00);

  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

:deep(.auth) {
  border-radius: 4px;
  border: 1px solid var(--success-3, #aade97);
  background: var(--success-1, #f0ffe8);

  color: var(--success-6, #4dac39);

  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

:deep(.expired) {
  overflow: hidden;
  color: var(--error-6, #f53f3f);
  text-overflow: ellipsis;

  /* medium/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  padding-left: 10px;
}

:deep(.ivu-tabs-bar) {
  margin-bottom: 0px;
}

:deep(.column-info) {
  display: flex;
  flex-direction: column;
  padding: 12px 0px;

  span {
    color: var(--font_black-1, rgba(0, 0, 0, 0.9));
    /* regular/12 */
    font-family: 'PingFang SC';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */

    overflow: hidden;

    text-overflow: ellipsis;
  }

  .grey-info {
    color: var(--font_black-2, rgba(0, 0, 0, 0.6));
    white-space: nowrap; /* 禁止文本换行 */
    overflow: hidden;    /* 隐藏溢出内容 */
    text-overflow: ellipsis; /* 使用省略号表示溢出内容 */
  }
}

:deep(.oper-info) {
  color: #3853e2 !important;

  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

</style>
