<template>
  <div>
    <Modal v-model="show" :mask-closable="false" class-name="abnormal-goods-select-modal" title="异常商品选择" @on-cancel="cancel">
      <Input v-model="searchGoodName" suffix="ios-search" placeholder="请输入商品名称" @on-change="handleSearchGoodName" />
      <Table border :columns="tableColumns" :data="tableData" @on-selection-change="onSelectionChange">
        <template slot-scope="{ row }" slot="index">
          <div>{{ (pageNumber - 1) * pageSize + (row._index + 1) }}</div>
        </template>
        <template slot-scope="{ row }" slot="itemName">
          <div>{{ row.itemName }} {{ row.itemSpec }}/{{ row.itemUnit }}</div>
        </template>
      </Table>
      <div class="footer">
        <div class="footer-left">
          已选 <span class="red">{{ selectedRows.length }}</span> 项/共 {{ total }} 项数据
        </div>
        <Page class="footer-page" :total="total" :page-size="pageSize" :current="pageNumber" @on-change="handlePageChange" />
      </div>
      <div slot="footer">
        <Button class="cancel-btn" @click="cancel">取消</Button>
        <Button class="confirm-btn" type="warning" @click="chooseGood">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getConsigneeGoodsList } from '@/common/service/reorderSign'
export default {
  props: {
    modalShow: {
      type: Boolean,
      default: false
    },
    // 回执ID
    epodId: {
      type: String,
      default: ''
    },
    // 已选择的异常商品列表
    selectedAbnormalGoodsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // 弹框显示状态
      show: false,
      // 搜素商品名称
      searchGoodName: '',
      tableColumns: [
        {
          type: 'selection',
          width: 34,
          align: 'center'
        },
        {
          key: 'index',
          title: '序号',
          width: 42,
          align: 'center',
          slot: 'index'
        },
        {
          title: '商品编码',
          key: 'itemCode',
          width: 128
        },
        {
          title: '商品名称',
          key: 'itemName',
          width: 324,
          slot: 'itemName'
        },
        {
          title: '生产企业',
          key: 'itemManufacturer',
          width: 324
        }
      ],
      tableData: [],
      total: 30,
      pageSize: 10,
      pageNumber: 1,
      // 已选商品
      selectedRows: []
    }
  },
  watch: {
    modalShow(val) {
      if (val) {
        this.show = val
        this.pageNumber = 1
        this.tableData = []
        this.getPageData()
      }
    }
  },
  methods: {
    // 搜索商品
    handleSearchGoodName() {
      this.pageNumber = 1
      this.getPageData()
    },
    // 获取页面数据
    getPageData() {
      this.selectedRows = []
      getConsigneeGoodsList({
        pageNum: this.pageNumber,
        pageSize: this.pageSize,
        condition: {
          epodId: this.epodId,
          itemType: 'normal',
          itemName: this.searchGoodName
        }
      }).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.itemList ? res.data.itemList : []

          // 已选择的异常商品-禁止选择
          let selectedAbnormalGoodsCodes = this.selectedAbnormalGoodsList.map(i => i.id)
          this.tableData.forEach(item => {
            if (selectedAbnormalGoodsCodes.indexOf(item.id) > -1) {
              item._disabled = true
            }
          })

          this.total = res.data.total
          this.pageSize = res.data.pageSize
          this.pageNumber = res.data.pageNum
        }
      })
    },
    // 选中项发生改变
    onSelectionChange(val) {
      this.selectedRows = val
      // this.$set(this, 'selectedRows', val)
    },
    // 切换分页
    handlePageChange(pageNumber) {
      this.pageNumber = pageNumber
      // 获取页面数据
      this.getPageData()
    },
    // 选择商品-点击确定按钮
    chooseGood() {
      if (this.selectedRows.length === 0) {
        this.$message.info('请至少选择一条数据')
        return
      }
      this.show = false
      this.$emit('update:modalShow', false)
      this.$emit('saveGoods', this.selectedRows)
    },
    // 取消
    cancel() {
      this.show = false
      this.$emit('update:modalShow', false)
    }
  }
}
</script>


<style lang="less" scoped>
:deep(.abnormal-goods-select-modal) {
  // 弹框垂直居中
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    // 弹框垂直居中
    top: 0;

    width: 886px !important;
    .ivu-modal-content {
      border-radius: 8px;
      .ivu-modal-header {
        padding: 14px 16px 0px;
        border: none;
        .ivu-modal-header-inner {
          color: rgba(0, 0, 0, 0.9);
          /* medium/16 */
          font-family: 'PingFang SC';
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }
      }
      .ivu-modal-body {
        .ivu-input-wrapper {
          width: 320px;
          .ivu-input {
            padding: 5px 8px;
            &::placeholder {
              overflow: hidden;
              color: rgba(0, 0, 0, 0.4);
              text-overflow: ellipsis;
              white-space: nowrap;
              /* Body/Medium */
              font-family: 'PingFang SC';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
            }
          }
        }

        .ivu-table-wrapper {
          margin: 12px 0;
          border-bottom: 1px solid transparent;
          .ivu-table {
            .ivu-table-cell {
              padding: 0;
            }
            tr th,
            tr td {
              height: 36px;
              padding: 0 8px;
            }
            .ivu-table-header {
              color: rgba(0, 0, 0, 0.9);
              text-align: center;
              /* regular/12 */
              font-family: 'PingFang SC';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 36px; /* 166.667% */
            }
            .ivu-table-tbody {
              color: rgba(0, 0, 0, 0.9);
              /* regular/12 */
              font-family: 'PingFang SC';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 166.667% */
            }
          }
        }

        .footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 28px;
          .footer-left {
            color: rgba(0, 0, 0, 0.6);
            text-align: center;
            /* Body/Small */
            font-family: 'PingFang SC';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 166.667% */
            .red {
              color: var(--brand-7, #e67d00);
            }
          }
          .footer-page {
            .ivu-page-item {
              min-width: 28px;
              width: 28px;
              height: 28px;
              border-radius: 4px;
              border: 1px solid var(--gray-3, #e7e7e7);
              color: rgba(0, 0, 0, 0.6);
              text-align: center;
              font-family: 'PingFang SC';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 28px; /* 166.667% */
            }
            .ivu-page-prev,
            .ivu-page-next,
            .ivu-page-item-jump-prev,
            .ivu-page-item-jump-next {
              min-width: 28px;
              width: 28px;
              height: 28px;
              line-height: 28px; /* 166.667% */
            }
          }
        }
      }
      .ivu-modal-footer {
        border: none;
        padding: 0px 16px 14px;
      }
    }
  }
}
</style>