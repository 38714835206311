<template>
  <div>
    <SearchBar :keyword="keyword" />
    <div class="sign-content" style="line-height: 22px; font-size: 14px; display: flex; align-items: center">
      <Breadcrumb separator="<div class='iconfonts'></div>">
        <BreadcrumbItem to="/center/index" class="can-click black_1">个人中心</BreadcrumbItem>
        <BreadcrumbItem class="can-click black_1"><a @click="toElectroncEvidence()">电子返单</a></BreadcrumbItem>
        <BreadcrumbItem>电子返单签收</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <!-- 正常签收 -->
    <div v-if="!isWaitSign">
      <div class="main" style="position: relative">
        <Spin size="large" fix v-if="validateLoading">
          <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          <div>身份验证中...</div>
        </Spin>
        <div v-if="signList.length > 1" class="sign-num">待签收文件 ({{ signList.length }})</div>
        <div class="sign-list">
          <div v-if="signList.length > 1" class="sign-list-tabs">
            <div
              v-for="(signListItem, signListIndex) in signList"
              :key="signListIndex"
              class="sign-list-tab"
              :class="currentTabIndex === signListIndex ? 'active' : ''"
              @click="changeFileTab(signListIndex)"
            >
              文件{{ signListIndex + 1 }}
            </div>
          </div>
          <div class="sign-list-content" v-if="currentSignFile">
            <div class="company-title">{{ currentSignFile.orgName }}</div>
            <div class="company-total">
              <div class="c-t-l">
                <span>{{ currentSignFile.orderNum }} 笔订单</span>
                <span>｜</span>
                <span>{{ currentSignFile.itemSortNum }} 种商品</span>
                <span>共 {{ currentSignFile.itemNum }} 件</span>
                <span>｜</span>
                <span>总金额</span>
                <span class="red">¥{{ currentSignFile.amount }}</span>
              </div>
              <div class="c-t-r">文件编号：{{ currentSignFile.epodNo }}<span @click="copyCode(currentSignFile.epodNo)">复制</span></div>
            </div>
            <div class="sign-list-content-bottom">
              <div class="s-l-c-b-l">
                <div class="full-screen" @click="onPreview">
                  <span>全屏预览</span>
                  <img src="@/assets/images/electronicSign/expand.png" alt="" />
                </div>
                <!-- <iframe :src="'/apis/rym-file/fileCenter/downloadPdf?fileId=11effe3b2508bdc17d2b891dca0e2ez'" style="width: 360px; height: 510px"></iframe> -->
                <iframe :src="'/apis/rym-file/fileCenter/downloadPdf?fileId=' + currentSignFile.signFileId" style="width: 360px; height: 510px"></iframe>
              </div>
              <div class="s-l-c-b-r">
                <div class="abnormal">
                  <div class="title">标记异常商品</div>
                  <div class="tip">如遇到货物破损等异常签收情况，可在下方备注</div>
                  <Button type="warning" icon="md-add" @click="addAbnormalGoods">添加异常商品</Button>
                  <div v-if="currentSignFile.abnormalGoodsList.length > 0" class="abnormal-goods">
                    <div class="abnormal-goods-header">
                      <div class="col col-1">品种</div>
                      <div class="col col-2"><span class="red">*</span><span>异常类型</span></div>
                      <div class="col col-3"><span class="red">*</span><span>异常数量</span></div>
                      <div class="col col-4">补充</div>
                      <div class="col col-5">操作</div>
                    </div>
                    <div v-for="(item, index) in currentSignFile.abnormalGoodsList" :key="index" class="abnormal-goods-body">
                      <div class="col col-1">
                        <div class="good-name">{{ item.itemName }} {{ item.itemSpec }}</div>
                        <div class="good-company">{{ item.itemManufacturer }}</div>
                        <div class="good-code">{{ item.itemCode }}</div>
                      </div>
                      <div class="col col-2">
                        <Select class="abnormal-num" clearable v-model="item.abnormalType">
                          <Option v-for="item in abnormalTypeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                        </Select>
                      </div>
                      <div class="col col-3">
                        <div>
                          <InputNumber class="abnormal-num" v-model="item.abnormalNum" :min="1" :max="99999999" :precision="0" placeholder="请输入" />
                          <span class="unit">{{ item.itemUnit }}</span>
                        </div>
                      </div>
                      <div class="col col-4">
                        <Input class="abnormal-num" v-model="item.abnormalRemark" :maxlength="50" placeholder="请输入" />
                      </div>
                      <div class="col col-5">
                        <div class="del-btn" @click="delAbnormalGoods(index)">删除</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="certificate">
                  <div class="title">凭证</div>
                  <div style="color: rgba(0, 0, 0, 0.6); font-size: 12px; margin-bottom: 16px">支持图片格式png、jpg、jpeg，最多上传10张图片，每张大小最多3MB</div>
                  <CustomerUpload :files.sync="currentSignFile.certificatePicList" :count="10" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btns" v-if="signList[0] && !validateLoading">
        <div class="btns-l">
          <Icon class="icon" type="ios-alert-outline" />
          <span class="tip">提示：电子签收具有和纸质签收同等的法律效力！</span>
        </div>
        <div class="btns-r">
          <span class="user">签收人：{{ signList[0].consigneeName }}</span>
          <div class="btn" @click="confirmSign">确认签收</div>
        </div>
      </div>
    </div>
    <!-- 等待签收 -->
    <div v-if="isWaitSign" class="sign-success">
      <div class="pic-placeholder">
        <img src="@/assets/images/electronicSign/sign-success.png" alt="" />
      </div>
      <div class="tip1">正在签收，请您耐心等待......</div>
      <div class="tip2">文件过大时，可能需要花费几分钟</div>
      <div class="tip3" @click="toElectroncEvidence()">
        <span>返回待签收列表( {{ waitTime }}s )</span>
        <img src="@/assets/images/electronicSign/sign-success-chevron-right.png" alt="" />
      </div>
    </div>

    <!-- 添加异常商品 -->
    <addAbnormalGoods
      v-if="currentSignFile"
      :modalShow.sync="addAbnormalGoodsFlag"
      :epodId="currentSignFile.epodId"
      :selectedAbnormalGoodsList="currentSignFile.abnormalGoodsList"
      @saveGoods="saveAbnormalGoods"
    ></addAbnormalGoods>

    <!-- 确认签收提示 -->
    <Modal v-model="confirmSignDialog" title="签收提示" :mask-closable="false" class-name="sign-tip-modal" @on-cancel="confirmSignDialog = false">
      <div>请仔细确认待签收文件内容，确保本人签收。</div>
      <div slot="footer">
        <Button class="cancel-btn" @click="confirmSignDialog = false">我再想想</Button>
        <Button class="confirm-btn" type="warning" @click="confirmSignContinue">确认签收</Button>
      </div>
    </Modal>
    <!-- 签收异常提示 -->
    <Modal v-model="abnormalTipFlag" title="签收提示" :mask-closable="false" class-name="sign-tip-modal" @on-cancel="abnormalTipFlag = false">
      <div>{{ abnormalTip }}</div>
      <div slot="footer">
        <Button class="confirm-btn" type="warning" @click="abnormalTipFlag = false">我知道了</Button>
      </div>
    </Modal>
    <!-- <Modal v-model="modal12" title="签收提示" class-name="sign-tip-modal" @on-ok="ok" @on-cancel="cancel">
      <div>【张仲景大药房】，您在【华润河南医药有限公司】的【电子返单签收认证】未开启，无法签收，如有疑问，请联系业务员处理。</div>
      <div slot="footer">
        <Button class="confirm-btn" type="warning">我知道了</Button>
      </div>
    </Modal> -->
    <!-- 有校验未通过的商品 -->
    <Modal v-model="hasNoPassGoods" title="提示" :mask-closable="false" class-name="sign-tip-modal">
      <div v-for="(item, index) in noPassGoodsTipArr" :key="index">{{ item }}</div>
      <div slot="footer">
        <Button class="confirm-btn" type="warning" @click="hasNoPassGoods = false">我知道了</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { submitConsigneeBatch, getSignReceiptList, getPreviewCode } from '@/common/service/reorderSign'
import CustomerUpload from './components/CustomerUploadPrivate.vue'
// import CustomerUpload from '../../personnal/information/components/CustomerUploadPrivate.vue'
import addAbnormalGoods from './components/addAbnormalGoods.vue'
import { api } from '@/common/service/config'

export default {
  components: {
    CustomerUpload,
    addAbnormalGoods
  },
  data() {
    return {
      // 关键词
      keyword: '',
      // 是否等待签收
      isWaitSign: undefined,

      // 返回待签收列表-剩余时间
      waitTime: 3,
      // 计时器
      timer: null,
      // 待签收文件列表
      signList: [],
      // 当前tab页
      currentTabIndex: 0,
      abnormalTypeList: [
        {
          value: '破损（在库、在途破损）',
          label: '破损（在库、在途破损）'
        },
        {
          value: '挤压（自配送、租车、物流、验收后发现挤压）',
          label: '挤压（自配送、租车、物流、验收后发现挤压）'
        },
        {
          value: '货物丢失',
          label: '货物丢失'
        },
        {
          value: '货物信息不符',
          label: '货物信息不符'
        },
        {
          value: '批号磨损',
          label: '批号磨损'
        },
        {
          value: '效期近',
          label: '效期近'
        },
        {
          value: '生产日期长',
          label: '生产日期长'
        },
        {
          value: '零批号拒收',
          label: '零批号拒收'
        },
        {
          value: '客户计划报错',
          label: '客户计划报错'
        },
        {
          value: '非原箱整件拒收',
          label: '非原箱整件拒收'
        },
        {
          value: '随货同行票据等资料不全/缺少拒收',
          label: '随货同行票据等资料不全/缺少拒收'
        },
        {
          value: '其他',
          label: '其他'
        }
      ],
      // 添加异常商品弹窗
      addAbnormalGoodsFlag: false,
      // 是否有校验未通过的商品
      hasNoPassGoods: false,
      // 校验未通过的商品提示
      noPassGoodsTipArr: [],
      // 客户编码
      clientCode: '',
      // 客户唯一编码
      // customNo: '',
      // 回执文件id
      epodIds: '',
      // 确认签收弹窗
      confirmSignDialog: false,
      // 异常提示-弹窗
      abnormalTipFlag: false,
      // 异常提示-内容
      abnormalTip: '',
      // 身份验证中
      validateLoading: false,
    }
  },
  computed: {
    // 当前tab页文件
    currentSignFile() {
      return this.signList[this.currentTabIndex]
    }
  },
  created() {
    this.isWaitSign = false
    setTimeout(() => {
      this.$emit('footer', false)
    })

    // 客户编码
    this.clientCode = this.$route.query.clientCode
    // 客户唯一编码
    // this.customNo = this.$route.query.customNo
    // 回执文件id
    this.epodIds = this.$route.query.epodIds
    // 获取签收单据列表
    this.getSignReceiptList()
  },
  watch: {
    waitTime(newValue) {
      if (newValue <= 0) {
        clearInterval(this.timer)
        this.toElectroncEvidence()
      }
    }
  },
  methods: {
    // 跳转电子返单页面
    toElectroncEvidence() {
      this.$router.replace({
        path: '/electroncEvidence/index'
      })
    },
    // 文件预览
    onPreview() {
      let url = '/apis/rym-file/fileCenter/downloadPdf?fileId=' + this.currentSignFile.signFileId
      // let url = '/apis/rym-file/fileCenter/downloadPdf?fileId=11effe3b2508bdc17d2b891dca0e2ez'
      window.open(url, '_blank')
    },
    // 获取签收单据列表
    getSignReceiptList() {
      getSignReceiptList({
        clientCode: this.clientCode,
        epodIds: this.epodIds
      })
        .then(res => {
          if (res.code === 200) {
            this.signList = res.data.detail.map(item => {
              this.$set(item, 'abnormalGoodsList', [])
              return item
            })
          }
        })
        .catch(err => {})
    },
    // 获取预览文件code
    // getPreviewCode(item) {
    //   return new Promise(resolve => {
    //     getPreviewCode({
    //       // fileId: item.signFileId,
    //       fileId: '11effe3b2508bdc17d2b891dca0e2ez'
    //     }).then(res => {
    //       console.log('res', res)
    //       if (res.code === 200) {
    //         resolve(res.data)
    //         // item.pdfUrl = api.fileCenterUrl + 'onlinePreviewNew?code=' + res.data
    //         // console.log('item.pdfUrl', item.pdfUrl)
    //       }
    //     })
    //   })
    // },
    // 复制文件编码code
    copyCode(code) {
      this.$copyText(code).then(
        e => {
          this.$message.success('复制成功')
        },
        e => {
          this.$message.error('复制失败')
        }
      )
    },
    // 校验每条数据的异常类型和异常数量必填
    checkRequired() {
      let noPassArr = []
      this.currentSignFile.abnormalGoodsList.forEach(element => {
        if (!element.abnormalType || !element.abnormalNum) {
          noPassArr.push(element)
        }
      })
      if (noPassArr.length > 0) {
        // 有商品未填写异常类型或异常数量
        let noPassGoodsTipArr = []
        noPassArr.forEach(item => {
          if (!item.abnormalType && !item.abnormalNum) {
            noPassGoodsTipArr.push('【' + item.itemName + '】' + '请选择异常类型，请填写异常数量')
          } else if (!item.abnormalType) {
            noPassGoodsTipArr.push('【' + item.itemName + '】' + '请选择异常类型')
          } else if (!item.abnormalNum) {
            noPassGoodsTipArr.push('【' + item.itemName + '】' + '请填写异常数量')
          }
        })
        this.noPassGoodsTipArr = noPassGoodsTipArr
        this.hasNoPassGoods = true
        return false
      } else {
        return true
      }
    },
    // 切换文件tab
    changeFileTab(signListIndex) {
      // 校验每条数据的异常类型和异常数量必填
      let pass = this.checkRequired()
      if (!pass) {
        return
      }
      this.currentTabIndex = signListIndex
    },
    // 添加异常商品
    addAbnormalGoods() {
      this.addAbnormalGoodsFlag = true
    },
    // 保存异常标记商品
    saveAbnormalGoods(list) {
      let tempArr = list.map(item => {
        this.$set(item, 'abnormalType', '')
        this.$set(item, 'abnormalNum', null)
        this.$set(item, 'abnormalRemark', '')
        return item
      })

      this.currentSignFile.abnormalGoodsList = [...this.currentSignFile.abnormalGoodsList, ...tempArr]
    },
    // 删除异常标记商品
    delAbnormalGoods(index) {
      this.currentSignFile.abnormalGoodsList.splice(index, 1)
    },
    // 点击-确认签收-按钮
    confirmSign() {
      // 校验每条数据的异常类型和异常数量必填
      let pass = this.checkRequired()
      if (!pass) {
        return
      }
      // 确认签收弹窗
      this.confirmSignDialog = true
    },
    // 确认签收弹窗-点击-确认签收
    confirmSignContinue() {
      this.confirmSignDialog = false
      this.validateLoading = true;
      let params = []
      this.signList.forEach(signFile => {
        let abnormalFileIds = signFile.certificatePicList ? signFile.certificatePicList.map(item => item.fileId).join(',') : ''
        params.push({
          // 客户唯一编码
          customNo: this.clientCode,
          // 机构编码
          orgNo: signFile.orgNo,
          // 待签收的文件
          unsignFileId: signFile.signFileId,
          // 签收人
          signUser: signFile.consigneeName,
          // 回执ID
          epodId: signFile.epodId,
          // 异常凭证
          abnormalFileIds: abnormalFileIds,
          // 异常商品列表
          itemList: signFile.abnormalGoodsList
        })
      })
      submitConsigneeBatch(params)
        .then(res => {
          console.log('res', res)
          this.validateLoading = false;
          this.isWaitSign = true
          this.timer = setInterval(() => {
            this.waitTime--
          }, 1000)
        })
        .catch(err => {
          console.log('err', err)
          this.validateLoading = false;
          this.abnormalTipFlag = true
          this.abnormalTip = err.data.message
        })
    }
  }
}
</script>

<style lang="less" scoped>
.sign-content {
  width: 1200px;
  margin: auto;
}
.main {
  // min-height: calc(100vh - 68px);
  padding: 24px;
  width: 1200px;
  margin: 16px auto 76px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  .sign-num {
    color: rgba(0, 0, 0, 0.9);
    /* medium/18 */
    font-family: 'PingFang SC';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    padding-bottom: 16px;
    border-bottom: 1px solid var(--gray-3, #eee);
    margin-bottom: 16px;
  }
  .sign-list {
    display: flex;
    .sign-list-tabs {
      width: 80px;
      margin-right: 16px;
      .sign-list-tab {
        display: flex;
        width: 80px;
        height: 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: rgba(0, 0, 0, 0.9);
        /* medium/14 */
        font-family: 'PingFang SC';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        margin-bottom: 12px;
        cursor: pointer;
        &.active {
          border-radius: 4px;
          border: 1px solid var(--brand-6, #f99d33);
          background: var(--brand-1, #fff8e8);
          color: var(--brand-7, #e67d00);
        }
      }
    }
    .sign-list-content {
      width: 100%;
      .company-title {
        color: rgba(0, 0, 0, 0.9);
        /* medium/16 */
        font-family: 'PingFang SC';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        margin-bottom: 4px;
      }
      .company-total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        .c-t-l {
          display: flex;
          align-items: center;

          span {
            margin-right: 8px;
            color: rgba(0, 0, 0, 0.9);
            /* regular/14 */
            font-family: 'PingFang SC';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }
          .red {
            color: var(--error-7, #cb272d);
            /* medium/18 */
            font-family: 'PingFang SC';
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 144.444% */
          }
        }
        .c-t-r {
          color: rgba(0, 0, 0, 0.6);
          /* regular/14 */
          font-family: 'PingFang SC';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
          span {
            cursor: pointer;
            margin-left: 8px;
          }
        }
      }
      .sign-list-content-bottom {
        display: flex;
        .s-l-c-b-l {
          position: relative;
          width: 360px;
          height: 510px;
          border: 1px solid var(--gray-3, #eee);
          margin-right: 24px;
          .full-screen {
            position: absolute;
            right: 16px;
            top: 70px;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.6);
            width: 82px;
            height: 28px;
            display: flex;
            padding: 0px 8px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            cursor: pointer;
            span {
              color: var(--fontcolor-white-0, #fff);
              font-family: 'PingFang SC';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px; /* 116.667% */
              white-space: nowrap;
            }
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
        .s-l-c-b-r {
          flex: 1;
          .abnormal {
            margin-bottom: 24px;
            .title {
              color: rgba(0, 0, 0, 0.9);
              /* medium/16 */
              font-family: 'PingFang SC';
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
              margin-bottom: 4px;
            }
            .tip {
              color: rgba(0, 0, 0, 0.6);
              /* regular/12 */
              font-family: 'PingFang SC';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 166.667% */
              margin-bottom: 16px;
            }
            .abnormal-goods {
              margin-top: 16px;
              .abnormal-goods-header {
                display: flex;
                .col {
                  display: flex;
                  height: 40px;
                  padding: 0px 8px;
                  align-items: center;
                  background: var(--gray-1, #fafafa);
                  box-sizing: border-box;
                  color: rgba(0, 0, 0, 0.9);
                  /* regular/12 */
                  font-family: 'PingFang SC';
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px; /* 166.667% */
                  span.red {
                    color: #cb272d;
                  }
                  &.col-1 {
                    width: 268px;
                  }
                  &.col-2 {
                    width: 112px;
                  }
                  &.col-3 {
                    width: 112px;
                  }
                  &.col-4 {
                    flex: 1;
                  }
                  &.col-5 {
                    width: 48px;
                  }
                }
              }
              .abnormal-goods-body {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-bottom: 1px solid var(--gray-3, #eee);
                .col {
                  height: 80px;
                  padding: 8px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  .abnormal-num {
                    :deep(.ivu-select-selected-value),
                    :deep(.ivu-input-number-input),
                    :deep(.ivu-input) {
                      padding: 8px;
                      color: rgba(0, 0, 0, 0.9);
                      /* regular/12 */
                      font-family: 'PingFang SC';
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 12px;
                    }
                    :deep(.ivu-select-selected-value) {
                      padding: 8px 20px 8px 8px;
                    }
                    :deep(.ivu-select-placeholder),
                    :deep(.ivu-input-number-input::placeholder),
                    :deep(.ivu-input::placeholder) {
                      color: rgba(0, 0, 0, 0.4);
                      font-family: 'PingFang SC';
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                    }
                    :deep(.ivu-select-dropdown) {
                      padding: 0px;
                      width: 270px;
                      max-height: 344px;
                    }
                    :deep(.ivu-select-dropdown-list) {
                      padding: 4px;
                    }
                    :deep(.ivu-select-item),
                    :deep(.ivu-select-item-focus) {
                      border-radius: 4px;
                      display: flex;
                      height: 28px;
                      padding: 0px 12px;
                      align-items: center;
                      gap: 8px;
                      align-self: stretch;

                      /* regular/12 */
                      font-family: 'PingFang SC';
                      font-size: 12px !important;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 20px; /* 166.667% */
                    }
                    :deep(.ivu-select-item) {
                      color: rgba(0, 0, 0, 0.9);
                      // &:hover {
                      //   background: var(--brand-1, #fff8e8);
                      // }
                    }
                    :deep(.ivu-select-item-focus) {
                      color: var(--brand-7, #e67d00) !important;
                      background: var(--brand-1, #fff8e8);
                    }
                    :deep(.ivu-select-selection:hover),
                    :deep(.ivu-select-selection-focused) {
                      border-color: #fab15c;
                    }
                  }

                  &.col-1 {
                    width: 268px;
                    div {
                      width: 100%;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      &.good-name {
                        color: rgba(0, 0, 0, 0.9);
                        font-family: 'PingFang SC';
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px; /* 166.667% */
                      }
                      &.good-company,
                      &.good-code {
                        color: rgba(0, 0, 0, 0.6);
                        /* regular/12 */
                        font-family: 'PingFang SC';
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                      }
                    }
                  }
                  &.col-2 {
                    width: 112px;
                    .abnormal-num {
                      width: 96px;
                    }
                  }
                  &.col-3 {
                    width: 112px;
                    .abnormal-num {
                      width: 76px;
                    }
                    .unit {
                      color: rgba(0, 0, 0, 0.9);
                      /* regular/12 */
                      font-family: 'PingFang SC';
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 20px; /* 166.667% */
                      margin-left: 8px;
                    }
                  }
                  &.col-4 {
                    flex: 1;
                    .abnormal-num {
                      width: 100%;
                    }
                  }
                  &.col-5 {
                    width: 48px;
                    .del-btn {
                      color: var(--hblue-6, #3853e2);
                      /* regular/12 */
                      font-family: 'PingFang SC';
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 20px; /* 166.667% */
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
          .certificate {
            .title {
              color: rgba(0, 0, 0, 0.9);
              /* medium/16 */
              font-family: 'PingFang SC';
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }
}
.btns {
  box-shadow: 4px 0px 20px 0px rgba(0, 0, 0, 0.08);
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  padding: 16px 24px;
  width: 1200px;
  height: 60px;
  border-radius: 8px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btns-l {
    .icon {
      color: #f99d33;
      font-size: 16px;
      font-weight: 600;
      margin-right: 8px;
    }
    .tip {
      color: rgba(0, 0, 0, 0.9);
      /* regular/14 */
      font-family: 'PingFang SC';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }
  .btns-r {
    display: flex;
    align-items: center;
    .user {
      color: rgba(0, 0, 0, 0.9);
      /* regular/14 */
      font-family: 'PingFang SC';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      margin-right: 24px;
    }
    .btn {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 92px;
      height: 36px;
      border-radius: 4px;
      background: var(--brand-6, #f99d33);
      color: var(--font_white-0, #fff);
      /* regular/14 */
      font-family: 'PingFang SC';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }
}
:deep(.sign-tip-modal) {
  .ivu-modal-content {
    width: 480px;
    padding: 20px 24px;
    border-radius: 8px;
    .ivu-modal-header {
      padding: 0;
      border: none;
      .ivu-modal-header-inner {
        color: rgba(0, 0, 0, 0.9);
        /* medium/16 */
        font-family: 'PingFang SC';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
    }
    .ivu-modal-body {
      padding: 16px 0;
      color: rgba(0, 0, 0, 0.9);
      /* regular/14 */
      font-family: 'PingFang SC';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
    .ivu-modal-footer {
      padding: 0;
      border: none;
      .cancel-btn {
        color: rgba(0, 0, 0, 0.9);
        /* regular/14 */
        font-family: 'PingFang SC';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        padding: 0 12px;
        border-radius: 4px;
        border: 1px solid var(--gray-5, #dcdcdc);
      }
      .confirm-btn {
        color: var(--fontcolor-white-0, #fff);
        /* regular/14 */
        font-family: 'PingFang SC';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        padding: 0 12px;
        border-radius: 4px;
        background: var(--brand-6, #f99d33);
        margin-left: 12px;
      }
    }
    .ivu-modal-close {
      font-size: 14px;
      right: 18px;
      top: 14px;
    }
  }
}
// 签收成功
.sign-success {
  width: 1200px;
  margin: 16px auto 16px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  height: calc(100vh - 175px);
  overflow-y: hidden;
  padding: 24px;
  .pic-placeholder {
    margin: 40px auto 24px;
    text-align: center;
    width: 120px;
    height: 96px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .tip1 {
    color: rgba(0, 0, 0, 0.9);
    /* medium/16 */
    font-family: 'PingFang SC';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    text-align: center;
  }
  .tip2 {
    color: rgba(0, 0, 0, 0.6);
    /* regular/14 */
    font-family: 'PingFang SC';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    text-align: center;
    margin: 8px 0 4px;
  }
  .tip3 {
    color: var(--hbrand-6, #3853e2);
    /* regular/14 */
    font-family: 'PingFang SC';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-right: 4px;
    }
  }
}
</style>