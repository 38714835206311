// 开发环境域名配置
const IMG_BASE_PREFIX = 'https://rym-weixin-web.uat.crpcg.com'// 静态图片地址
// 默认开发环境

const api = {
  baseUrl: process.env.VUE_APP_BASE_URL,
  cmsUrl: process.env.VUE_APP_CMS_URL,
  baseFileUrl: process.env.VUE_APP_BASE_FILE_URL,
  filePreviewHttp: process.env.VUE_APP_PREVIEW_FILE_URL,
  baseRipplesUrl: process.env.VUE_APP_RIPPLES_URL,
  envName: process.env.VUE_APP_ENV_NAME,
  publicKey: process.env.VUE_APP_PUBLIC_KEY,
  recUrl: process.env.VUE_APP_REC_URL,
  remPassPublicKey: process.env.VUE_APP_REMEMBER_PASSWORD_PUBLIC_KEY,
  remPassPPKey: process.env.VUE_APP_REMEMBER_PASSWORD_PP_KEY,
  h5Url: process.env.VUE_APP_H5_URL,
  fileCenterUrl: process.env.VUE_APP_FILE_CENNTER_URL,
}

const keyMap = {
  'VUE_APP_BASE_URL': 'baseUrl',
  'VUE_APP_CMS_URL': 'cmsUrl',
  'VUE_APP_BASE_FILE_URL': 'baseFileUrl',
  'VUE_APP_PREVIEW_FILE_URL': 'filePreviewHttp',
  'VUE_APP_RIPPLES_URL': 'baseRipplesUrl',
  'VUE_APP_ENV_NAME': 'envName',
  'VUE_APP_REC_URL': 'recUrl',
  'VUE_APP_FILE_CENNTER_URL': 'fileCenterUrl',
}

const rPConfig = window.RPConfig || {}
rPConfig.items?.forEach(item => {
  if (item['key'] && item['value']) {
    api[keyMap[item['key']] || item['key']] = item['value']
  }
})
export {
  api,
  IMG_BASE_PREFIX
}
