<template>
  <div>
    <!-- Modal 组件 -->
    <Modal v-model="cantactModal" width="432" title="温馨提示" @on-cancel="handleCancel">
      <div style="text-align:left">
        <p style="color: rgba(0, 0, 0, 0.90);">{{ content }}</p>
      </div>
      <template v-if="showFooter" #footer>
        <Button type="default" style="color: rgba(0, 0, 0, 0.90);" @click="handlePlatform">联系平台</Button>
        <Button type="primary" @click="handleConfirm">联系商家</Button>
      </template>
    </Modal>
    <!--联系平台-->
    <Modal v-model="cantactPlatformModal" width="432" title="联系平台" :footer-hide="true" class="contact-platform">
      <div class="content" style="text-align:left;">
        <p>客服电话：<span class="text">{{ contactPhone }}</span> <span class="text copy-text" @click="onCopy">复制</span></p>
        <p>服务时间：<span class="text">工作日 09:00-17:30</span></p>
      </div>
    </Modal>
    <!--联系商家-->
    <!-- <Modal v-model="cantactShopModal" width="432" title="联系商家">
      <div class="content" style="text-align:left">
        <p>客服电话：{{ shopPhone }} <span class="copy-text" @click="onCopy">复制</span></p>
        <p>开票员（{{ }}）：{{ salePhone }} <span class="copy-text" @click="onCopy">复制</span></p>
        <p>服务时间：工作日 {{ serviceTime }}</p>
      </div>
    </Modal> -->
  </div>
</template>

<script>
import { copy } from '@/common/uni.js'
export default {
  props: {
    showModal: {
      default: false,
      type: Boolean
    },
    contactPhone: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // 控制模态对话框显示状态的数据属性
      content: '建议您先联系商家处理，如需平台支持再联系平台客服。',
      showFooter: true,
      cantactPlatformModal: false,
      cantactModal: false
    }
  },
  watch: {
    showModal(val) {
      if (val) {
        this.cantactModal = val
      }
    }
  },
  methods: {
    handlePlatform() {
      this.cantactModal = false
      this.$emit('on-visible-change')
      this.cantactPlatformModal = true
    },
    // 确定按钮的点击事件处理函数
    handleConfirm() {
      this.cantactModal = false
      this.$emit('on-visible-change')
      this.$router.push('/center/merchant')
    },
    onCopy() {
      this.$zhuge.track('客服-点击客服按钮', {
        '店铺名称': undefined,
        '客服类型': '平台客服'
      })
      copy(this.contactPhone)
    },
    handleCancel() {
      this.cantactModal = false
      this.$emit('on-visible-change')
    }
  }
}
</script>

<style scoped>
/* 你可以在这里添加你的样式 */
::v-deep .ivu-modal-header {
  border-bottom: none;
  padding: 20px 24px 0 24px;
  .ivu-modal-header-inner {
    color: rgba(0, 0, 0, 0.90);
  }
}
::v-deep .ivu-modal-header .ivu-modal-header-inner {
  color: rgba(0, 0, 0, 0.90);
}
::v-deep .ivu-modal-footer {
  border-top: none;
  padding: 0 24px 20px 24px;
}
::v-deep .ivu-modal-body {
    padding: 8px 24px 20px 24px;
}

.content {
  color: rgba(0, 0, 0, 0.60);
  .text {
    color: rgba(0, 0, 0, 0.90)
  }
  .copy-text {
    cursor: pointer;
  }
  p {
    line-height: 22px;
    margin-top: 8px;
  }
}
</style>
