<template>
  <div class="right-content">
    <CustHeader @change="search" @search="search" :searchHint="searchHint"></CustHeader>
    <Table style="width: 100%" :columns="columns"  :data="registrationList"></Table>
    <div style="width: 100%; padding-top: 12px">
      <Pagination :total="total"  :page-size-opts="[10, 20, 50, 100]" :size="reqParams.pageSize" :num="reqParams.pageNum" @change="pageChange" />
    </div>
  </div>
</template>
<script>
import { getMyCustomerInfoAPI } from '@/common/service'
import ComModal from '@/components/ComModal.vue'
import CustHeader from './components/CustHeader.vue'
import { getRegistrationList } from '@/common/service/consignee'
import { mapState } from 'vuex'
export default {
  name: 'ReceiptFiling',
  computed: {
    ...mapState('consignee', ['customMdmNo'])
  },
  components: { ComModal,CustHeader },
  data() {
    return {
      searchHint:"请输入商家名称/收货人姓名",
      // 组件数据
      reqParams: {
        condition: {
          customNo: '',
          searchKey: ''
        },
        pageNum: 1,
        pageSize: 10
      },
      total:0,
      registrationList: [],
      columns: [
        {
          title: '商家名称',
          key: 'orgName',
          tooltip: true,
          width: '300px',
        },
        {
          title: '电子签状态',
          key: 'receiveContact',
          width: '120px',
          render: (h, params) => {
            return h(
              'span',
              {
                class: this.getSignStatus(params.row).className,
              },
              this.getSignStatus(params.row).tagName
            )
          }
        },
        {
          title: '姓名',
          key: 'consigneeName',
          width: '104px',
        },
        {
          title: '联系方式',
          width: '120px',
          key: 'phone',
        },
        {
          title: '有效期',
          key: 'action',
          render: (h, params) => {
            return h('div', [h('span', {}, `${params.row.consigneeExpirationDateStart ? params.row.consigneeExpirationDateStart+"/" : ''}${params.row.consigneeExpirationDateEnd || '-'}`),h('span', {
              class:{
                'expired':params.row.expiredStatus
              },
              style:{
                display:params.row.expiredStatus? 'inline' : 'none'
              }
            }, '过期')])
          }
        }
      ],
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    pageChange(e){
      this.reqParams.pageNum = e.pageNumber
      this.reqParams.pageSize =  e.pageSize
      this.loadData()
    },
    getSignStatus(dataSource){
        if(dataSource.customerVisaStatus !== 'Y'){
            return {
               className:'un_open_auth tag',
               tagName:'未开启'
            }
        }
        if(dataSource.consigneeVisaStatus === 'Y'){
            return  {
                className:'auth tag',
                tagName:'已认证'
            } 
        }
        return {
            className:'un_auth tag',
            tagName:'未认证'
        }
    },
    loadData() {
      if(!this.customMdmNo){
        this.registrationList = []
        this.total = 0
        return
      }
      this.reqParams.condition.customNo = this.customMdmNo
      getRegistrationList(this.reqParams).then(res => {
        if (res.code === 200) {
          this.registrationList = res.data.list
          this.total = res.data.total
        }
      })
    },
    search(searchKey) {
      this.reqParams.condition.searchKey = searchKey
      this.reqParams.pageNum = 1
      this.total = 0
      this.loadData()
    },
  }
}
</script>
<style lang="less" scoped>
.right-content {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: 8px;
  background: #fff;
}

:deep(tbody .ivu-table-cell) {
  color: var(--font_black-1, rgba(0, 0, 0, 0.90));

  /* regular/12 */
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

:deep(thead .ivu-table-cell) {
  color: var(--font_black-1, rgba(0, 0, 0, 0.90));

  /* regular/12 */
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

:deep(.tag) {
  padding: 0px 4px;
  align-items: center;
  gap: 4px;
  justify-content: center;
}

:deep(.un_open_auth) {
  border-radius: 4px;
  border: 1px solid var(--error-3, #FBACA3);
  background: var(--error-1, #FFECE8);

  color: var(--error-6, #F53F3F);

  /* regular/12 */
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

:deep(.un_auth) {
  border-radius: 4px;
  border: 1px solid var(--brand-3, #FDDA9F);
  background: var(--brand-1, #FFF8E8);

  color: var(--brand-7, #E67D00);

  /* regular/12 */
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

:deep(.auth) {
  border-radius: 4px;
  border: 1px solid var(--success-3, #AADE97);
  background: var(--success-1, #F0FFE8);

  color: var(--success-6, #4DAC39);

  /* regular/12 */
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

:deep(.expired) {
  overflow: hidden;
  color: var(--error-6, #F53F3F);
  text-overflow: ellipsis;

  /* medium/12 */
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  padding-left: 8px;
}

</style>
