import CenterIndex from '@/views/personnal/center'
import RouterContainer from '@/views/order/order/detail/routerContainer.vue'
import AllorderRouterContainer from '@/views/order/allOrder/detail/routerContainer.vue'
import RouterBatchContainer from '@/views/order/batchOrder/component/routerBatchContainer.vue'
import SpeedOrder from '@/views/order/speedOrder/index.vue'
import PurchasePlan from '@/views/order/purchasePlan/index.vue'
import CodeMapping from '@/views/order/codeMapping/Index.vue'
import ElectroncEvidence from '@/views/order/electroncEvidence/index.vue'
import ReceiptFiling from '@/views/order/receiptFiling/index.vue'
import ReorderSign from '@/views/order/reorderSign/index.vue'
import ReorderSignDetail from '@/views/order/reorderSign/detail/index.vue'
export default [
  {
    path: '/order',
    redirect: '/order/list',
    component: CenterIndex,
    children: [
      {
        path: 'list',
        name: 'OrderList',
        component: () => import('@/views/order/order/index.vue'),
        meta: {
          title: '商城订单'
        }
      },
      {
        path: 'detail',
        redirect: '/order2/detail'
      },
      {
        path: 'evaluate/index',
        redirect: '/order2/evaluate/index'
      },
      {
        path: 'refund/index',
        name: 'RefundList',
        component: () => import('@/views/order/refund/index.vue'),
        meta: {
          title: '退款/售后'
        }
      },
      {
        path: 'outOfStock/index',
        name: 'OutOfStock',
        component: () => import('@/views/order/outOfStock/Index.vue'),
        meta: {
          title: '缺货登记'
        }
      },
      {
        path: 'refund/applyRefund',
        redirect: '/order2/refund/applyRefund'
      },
      {
        path: 'refund/detail',
        redirect: '/order2/refund/detail'
      },
      {
        path: '/receiptFiling/index',
        name: 'ReceiptFiling',
        component: ReceiptFiling,
        title: '收货备案'
      },
      {
        path: '/electroncEvidence/index',
        name: 'ElectroncEvidence',
        component: ElectroncEvidence,
        title: '电子返单'
      },
    ]
  },
  {
    path: '/order2',
    name: 'Order2',
    component: RouterContainer,
    children: [
      {
        path: 'detail',
        component: () => import('@/views/order/order/detail/index.vue'),
        meta: {
          title: '订单详情'
        }
      },
      {
        path: 'evaluate/index',
        name: 'Evaluate',
        component: () => import('@/views/order/evaluate/index.vue'),
        meta: {
          title: '评价'
        }
      },
      {
        path: 'refund/applyRefund',
        name: 'ApplyRefund',
        component: () => import('@/views/order/refund/applyRefund.vue'),
        meta: {
          title: '申请售后'
        }
      },
      {
        path: 'refund/detail',
        name: 'RefundDetail',
        component: () => import('@/views/order/refund/detail.vue'),
        meta: {
          title: '售后详情'
        }
      }
    ]
  },
  {
    path: '/initial-qualification',
    name: 'InitialQualification',
    component: () => import('@/views/order/qualificationReport/initialQualification.vue'),
    meta: {
      title: '商品首营'
    }
  },
  {
    path: '/drug-report',
    name: 'DrugReport',
    component: () => import('@/views/order/qualificationReport/drugReport.vue'),
    meta: {
      title: '药检报告'
    }
  },
  {
    path: '/reportPreview',
    name: 'ReportPreview',
    component: () => import('@/views/order/qualificationReport/reportPreview.vue')
  },
  {
    path: '/batch',
    name: 'Batch',
    component: RouterBatchContainer,
    children: [
      {
        path: 'selectShop',
        name: 'SelectShop',
        component: () => import('@/views/order/batchOrder/index'),
        meta: {
          title: '批量采购'
        }
      },
      {
        path: 'uploadTemplate',
        name: 'UploadTemplate',
        component: () => import('@/views/order/batchOrder/uploadTemplate'),
        meta: {
          title: '批量采购'
        }
      },
      {
        path: 'batchOrderList',
        name: 'BatchOrderList',
        component: () => import('@/views/order/batchOrder/batchOrderList'),
        meta: {
          title: '批量采购'
        }
      }
    ]
  },
  {
    path: '/speedOrder/index',
    name: 'SpeedOrder',
    component: SpeedOrder,
    title: '飞速下单'
  },
  {
    path: '/purchasePlan/index',
    name: 'PurchasePlan',
    component: PurchasePlan,
    title: '采购计划'
  },
  {
    path: '/codeMapping/index',
    name: 'CodeMapping',
    component: CodeMapping,
    title: '对码关系管理'
  },
  {
    path: '/allOrder',
    redirect: '/allOrder/list',
    component: CenterIndex,
    children: [
      {
        path: 'list',
        name: 'AllOrderList',
        component: () => import('@/views/order/allOrder/index.vue'),
        meta: {
          title: '全渠道订单'
        }
      },
      {
        path: '/detail',
        redirect: '/allorder/detail',
      },
    ]
  },
  {
    path: '/allorder',
    name: 'Allorder',
    component: AllorderRouterContainer,
    children: [
      {
        path: 'detail',
        component: () => import('@/views/order/allOrder/detail/index.vue'),
        meta: {
          title: '订单详情'
        }
      },
    ]
  },
  {
    path: '/reorderSign/index',
    name: 'ReorderSign',
    component: ReorderSign,
    title: '电子返单签收'
  },
  {
    path: '/reorderSign/detail/index',
    name: 'ReorderSignDetail',
    component: ReorderSignDetail,
    title: '电子返单签收详情'
  },
]
