<template>
    <Modal v-model="show" :title="title" @on-cancel="cancel">
        <div class="modal-content">
            <Form ref="formValidate" :model="formItem" :label-width="108">
                <FormItem label="选择添加方式" required >
                    <RadioGroup v-model="formItem.radio" @on-change="handleRadioChange">
                        <Radio label="1">方式一</Radio>
                        <Radio label="2">方式二</Radio>
                        <Radio label="3">方式三</Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem label="客户商品编码" prop="customerProductNo" :rules="[
                    {
                        required: formItem.radio === '1', message: formItem.radio === '1' ? '必填项' : '', trigger: 'change',
                    }
                ]">
                    <Input v-model="formItem.customerProductNo" placeholder="请输入内容"></Input>
                </FormItem>
                <FormItem label="商家名称" prop="orgId" :rules="[
                    {
                        required: true, message: '必填项', trigger: 'blur'
                    }
                ]">
                    <Select 
                        v-model="formItem.orgId" 
                        filterable 
                        :remote-method="getOrgList" 
                        :loading="orgLoading" 
                        label-in-value
                        @on-change="changeOrg">
                        <Option v-for="(item, index) in orgList" :key="index" :value="item.orgId" >
                            {{ item.orgAbbr }}
                        </Option>
                    </Select>
                </FormItem>
                <FormItem label="采购数量" prop="purchaseNum" :rules="[
                    {
                        required: true, message: '必填项', trigger: 'blur',type: 'number'
                    }
                ]">
                    <InputNumber v-model="formItem.purchaseNum" :min="1" :max="99999999" :precision="0" style="width: 364px;" placeholder="请输入内容" />
                </FormItem>
                <FormItem label="商家商品编码" prop="orgProductNo" :rules="[
                    {
                        required: formItem.radio == '2', message: '必填项', trigger: 'change',
                    }
                ]">
                    <Input v-model="formItem.orgProductNo" placeholder="请输入内容"></Input>
                </FormItem>
                <FormItem label="商品名称" prop="productName" :rules="[
                    {
                        required: formItem.radio == '3', message: '必填项', trigger: 'change',
                    }
                ]">
                    <Input v-model="formItem.productName" placeholder="请输入内容"></Input>
                </FormItem>
                <FormItem label="规格" prop="productSpec" :rules="[
                    {
                        required: formItem.radio == '3', message: '必填项', trigger: 'change',
                    }
                ]">
                    <Input v-model="formItem.productSpec" placeholder="请输入内容"></Input>
                </FormItem>
                <FormItem label="生产企业" prop="manufactureCompany" :rules="[
                    {
                        required: formItem.radio == '3', message: '必填项', trigger: 'change',
                    }
                ]">
                    <Input v-model="formItem.manufactureCompany" placeholder="请输入内容"></Input>
                </FormItem>
                <FormItem label="批准文号" prop="approvalNumber" :rules="[
                    {
                        required: formItem.radio == '3', message: '必填项', trigger: 'change',
                    }
                ]">
                    <Input v-model="formItem.approvalNumber" placeholder="请输入内容"></Input>
                </FormItem>
                <FormItem label="采购价" prop="purchasePrice">
                    <InputNumber 
                        v-model="formItem.purchasePrice" 
                        :min="0" 
                        :max="99999999"
                        style="width: 364px;" 
                        placeholder="请输入内容" />
                </FormItem>
            </Form>

        </div>
        <template #footer>
            <div class="fail-modal-footer">
                <Button type="default" @click="cancel">取消</Button>
                <Button type="primary" :loading="createPlanLoading" @click="ok">确认</Button>
            </div>
        </template>
    </Modal>
</template>

<script>
import { savePurchasePlanGoodDetail, listProductOrgNameByEs } from '@/common/service'

export default {
    name: 'AddGood',
    props: {
        btnDisabled: {
            type: Boolean,
            default: false
        },
        modalShow: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: '添加商品'
        },
        data: {
            type: Object,
            default: {}
        },
        purchasePlanId: {
            type: String,
            default: '0'
        }
    },
    components: {

    },
    data() {
        return {
            show: false,
            createPlanLoading: false,
            orgList: [],
            orgLoading: false,
            formItem: {
                radio: '1',
                customerProductNo: '',
                orgName: '',
                purchaseNum: null,
                orgProductNo: '',
                productName: '',
                productSpec: '',
                manufactureCompany: '',
                approvalNumber: '',
                purchasePrice: null,
                orgId: null
            },
        }
    },
    computed: {
    },
    watch: {
        modalShow(val) {
            if (val) {
                this.show = val;
                if (this.title == '修改商品') {
                    this.orgList = [
                        {
                            orgAbbr: this.data.orgName,
                            orgId: this.data.orgId
                        }
                    ]
                    setTimeout(() => {
                        this.formItem = {
                            radio: '1',
                            customerProductNo: this.data.customerProductNo,
                            orgName: this.data.orgName,
                            purchaseNum: this.data.purchaseNum,
                            orgProductNo: this.data.orgProductNo,
                            productName: this.data.productName,
                            productSpec: this.data.productSpec,
                            manufactureCompany: this.data.manufactureCompany,
                            approvalNumber: this.data.approvalNumber,
                            purchasePrice: this.data.purchasePrice,
                            orgId: this.data.orgId,
                            id: this.data.id
                        }
                        if (this.formItem.customerProductNo && this.formItem.orgName && this.formItem.purchaseNum) {
                            this.formItem.radio = '1'
                        } else if (this.formItem.orgProductNo && this.formItem.orgName && this.formItem.purchaseNum) {
                            this.formItem.radio = '2'
                        } else {
                            this.formItem.radio = '3'
                        }
                    }, 200)
                } else {
                    this.formItem = {
                        radio: '1',
                        customerProductNo: '',
                        orgName: '',
                        purchaseNum: null,
                        orgProductNo: '',
                        productName: '',
                        productSpec: '',
                        manufactureCompany: '',
                        approvalNumber: '',
                        purchasePrice: null,
                        orgId: null
                    }
                }
            }
        },
    },
    created() {

    },
    methods: {

        async getOrgList(query) {

            const params = {
                orgName: query,
                page: {
                    pageNumber: 1,
                    pageSize: 100
                }
            }
            if (query.trim() != '') {
                this.orgLoading = true;
                let res = await listProductOrgNameByEs(params)
                if (res.code == 200) {
                    this.orgList = res.data
                    this.orgLoading = false
                }
            } else {
                this.orgList = [];
            }
        },
        changeOrg(option) {
            this.formItem.orgId = option?.value || null
            
            this.formItem.orgName = option?.label || null
        },
    ok() {
        this.$refs['formValidate'].validate(async (valid) => {
            if (valid) {
                const purchasePrice = this.formItem?.purchasePrice ?? 0
                // if (purchasePrice <= 0) {
                //     this.$message.info('采购价必须为大于0的数字')
                //     return false
                // }
                const ngx = /(^[0-9]([0-9]+)?(\.[0-9]{1,6})?$)/
                if (purchasePrice > 0 && !ngx.test(purchasePrice)) {
                    this.$message.info('采购价必须为大于0的数字，最多两位小数')
                    return false
                }
                const params = {
                    ...this.formItem,
                    purchasePlanId: this.purchasePlanId
                }
                this.createPlanLoading = true
                try {
                    let res = await savePurchasePlanGoodDetail(params)
                    this.createPlanLoading = false
                    if (res.code == 200) {
                        this.$message.success('保存成功')
                        this.show = false;
                        this.$emit('saveSuccess')
                        this.$emit('update:modalShow', false)
                    } else {
                        this.$message.error(error?.data?.message)
                    }
                } catch (error) {
                    this.createPlanLoading = false
                    this.$message.error(error?.data?.message)
                }
            } else {
                this.$Message.error('存在必填项未填写');
            }
        })
    },
        cancel() {
            this.$nextTick(() => {
                this.$refs['formValidate'].resetFields();
                this.formItem.orgId = 0
                this.show = false;
                this.$emit('update:modalShow', false)
            })
        },
        handleRadioChange() {
            const formData = JSON.parse(JSON.stringify(this.formItem))
            setTimeout(() => {
                this.$refs['formValidate'].resetFields()
                this.formItem = formData
            }, 100)
        },
    }
}
</script>
<style scoped lang="less">
:deep(.ivu-modal-header) {
    border-bottom: none;
    padding: 20px 16px 0;
}

:deep(.ivu-modal-body) {
    padding: 24px;
}

:deep(.ivu-modal-footer) {
    border-top: none;

    .tip {
        position: absolute;
        left: 16px;
        bottom: 16px;
        color: rgba(0, 0, 0, 0.60);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        .download-icon {
            width: 20px;
            height: 20px;
        }

        .down-load {
            color: var(--hbrand-6, #3853E2);

            &:hover {
                cursor: pointer;
            }
        }

        .export {
            margin-left: 10px;
        }
    }

    .ivu-btn-primary[disabled] {
        background: var(--brand-3, #FDDA9F);
        color: #fff;
        border-color: #fdda9f;
    }

}

</style>