import { render, staticRenderFns } from "./index.vue?vue&type=template&id=487ef79b&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=487ef79b&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_40df3e800382af9a7a59c1fe33ba5098/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "487ef79b",
  null
  
)

export default component.exports