const state = {
  customMdmList: [
  ],
  customMdmNo: ''
}
const getters = {}
const mutations = {
  setCustomMdmList(state, data) {
    state.customMdmList = data
  },
  setCustomMdmNo(state, data) {
    state.customMdmNo = data
  },
}
const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
