<template>
  <div class="cust-header">
    <!--收货地址-->
    <div class="title">
      <div class="left">{{title}}</div>
      <div class="right" @click="changeCust" v-if="customMdmList.length > 1">
        <img src="../../../../assets/center/change.png" />
        <span >切换</span>
      </div>
    </div>
    <div style="display: flex; gap: 12px">
      <Input v-model="searchKey" :placeholder="searchHint" class="search-input" clearable />
      <Button type="primary" @click="search">搜索</Button>
      <Button @click="reset">重置</Button>
    </div>

    <ComModal :isShow.sync="editShow" :hintFoot="true">
      <RadioGroup v-model="selectMdmNo" @on-change="radioChange">
        <Radio :label="cust.customNo" v-for="cust in customMdmList" :key="cust.customNo">
          <span>{{ cust.customName }}</span>
        </Radio>
      </RadioGroup>
    </ComModal>

    <ComModal :isShow.sync="unCustMdmHintModal.show" :hintCancel="true" confirmText="我知道了" @confirm="unCustMdmHintModal.show = false">
      <div>{{unCustMdmHintModal.content}}</div>
    </ComModal>
  </div>
</template>
<script>
import ComModal from '@/components/ComModal.vue'
import { mapState, mapMutations } from 'vuex'
import { getCustomMdmList } from '@/common/service/consignee'
export default {
  name: 'CustHeader',
  computed: {
    ...mapState('consignee', ['customMdmList', 'customMdmNo']),
    title(){
      return this.customMdmList.find(item=> item.customNo === this.customMdmNo)?.customName || "请选择客户"
    }
  },
  props: {
    searchHint: {
      type: String,
      default: '',
    },
  },
  components: { ComModal },
  data() {
    return {
      editShow: false,
      selectMdmNo: '',
      searchKey: '',
      unCustMdmHintModal:{
        show:false,
        content:''
      },
    }
  },
  mounted() {
    this.getCustList()
  },
  methods: {
    ...mapMutations({
      setCustomMdmNo: 'consignee/setCustomMdmNo',
      setCustomMdmList: 'consignee/setCustomMdmList'
    }),
    changeCust(){
        if(this.customMdmList.length <2){
          return
        }
        this.editShow = true
    },
    getCustList(){
      const loginInfo = JSON.parse(localStorage.getItem('loginData') || '{}')?.value
      getCustomMdmList().then(res => {
          this.setCustomMdmList(res.data)
          if (this.customMdmList.length === 0) {
            this.unCustMdmHintModal.content = `当前账号【${loginInfo?.nick_name}】未在任何药店下收货备案，如有疑问，请联系业务员处理。`
            this.unCustMdmHintModal.show = true
          } else {
            if(this.customMdmList.length === 1){
              this.setCustomMdmNo(this.customMdmList[0].customNo)
              this.$emit('change', this.selectMdmNo)
              return
            }
            if(!this.customMdmNo){
              this.editShow = true
            }
          }
        }).catch(err => {
          this.setCustomMdmList([])
          this.setCustomMdmNo('')
          this.unCustMdmHintModal.content = `当前账号【${loginInfo?.nick_name}】未在任何药店下收货备案，如有疑问，请联系业务员处理。`
          this.unCustMdmHintModal.show = true
        })
    },
    radioChange() {
      this.editShow = false
      this.setCustomMdmNo(this.selectMdmNo)
      this.$emit('change', this.selectMdmNo)
    },
    reset() {
      this.searchKey = ''
      this.$emit('search', this.searchKey)
    },
    search() {
      this.$emit('search', this.searchKey)
    }
  }
}
</script>
<style lang="less" scoped>
.cust-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  
  .title {
    display: flex;
    padding: 0px 8px;
    align-items: center;
    gap: 16px;

    .left {
      color: var(--font_black-1, rgba(0, 0, 0, 0.9));
      /* medium/16 */
      font-family: 'PingFang SC';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }

    .right {
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
      }

      span {
        color: var(--font_black-1, rgba(0, 0, 0, 0.9));

        /* regular/14 */
        font-family: 'PingFang SC';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        padding-left: 4px;
      }
    }
  }
}

.ivu-radio-group {
  margin-bottom: -20px;
}

.ivu-radio-group-item {
  display: flex;
  padding: 12px 24px;
  align-items: center;
  align-self: stretch;
  margin-left: -24px;
  margin-right: -24px;
  width: 520px;
}

.ivu-radio-group-item:last-child {
  border-radius: 0px 0px 8px 8px;
}

.ivu-radio-wrapper-checked {
  background: var(--gray-15, #f5f5f5);
}

.search-input{
  width: 322px;
}
</style>
