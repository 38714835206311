<template>
  <div>
    <Loading :is-loading="isLoading" />
    <SearchBar :keyword="keyword" />
    <div class="sign-content">
      <div style="line-height: 22px; font-size: 14px;display: flex;align-items: center;">
        <Breadcrumb separator="<div class='iconfonts'></div>">
          <BreadcrumbItem to="/center/info" class="can-click black_1">个人中心</BreadcrumbItem>
          <BreadcrumbItem class="can-click black_1"><a @click="toSignPage()">电子返单</a></BreadcrumbItem>
          <BreadcrumbItem>签收详情</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div v-if="!isLoading" class="sign-list-content">
        <div class="company-title">{{item.orgName}}</div>
        <div class="company-total">
          <div class="c-t-l">
            <span>{{item.orderNum}} 笔订单</span>
            <span>｜</span>
            <span>{{item.itemSortNum}} 种商品</span>
            <span>共 {{item.itemNum}} 件</span>
            <span>｜</span>
            <span>总金额</span>
            <span class="red">¥{{item.amount}}</span>
          </div>
          <div class="c-t-r">
            文件编号：{{item.epodNo}}<span @click="copyCode(item.epodNo)" style="margin-left: 8px;cursor: pointer;">复制</span>
            <span>签收时间：{{item.signTime}}</span>
            <span>签收人：{{item.signUser}}</span>
          </div>
        </div>
        <div class="sign-list-content-bottom">
          <div class="s-l-c-b-l">
            <div class="full-screen" @click="onPreview(item.signFileId)">
                <span>全屏预览</span>
                <img src="@/assets/images/electronicSign/expand.png" alt="" />
            </div>
            <iframe v-if="item.signFileId" :src="`/apis/rym-file/fileCenter/downloadPdf?fileId=${item.signFileId}`" style="width: 360px; height: 510px"></iframe>
          </div>
          <div class="s-l-c-b-r">
            <div class="abnormal">
              <div class="title">异常商品</div>
              <div v-if="abnormalGoodsList.length > 0" class="abnormal-goods">
                <div class="abnormal-goods-header">
                  <div class="col col-1">品种</div>
                  <div class="col col-2"><span>异常类型</span></div>
                  <div class="col col-3"><span>异常数量</span></div>
                  <div class="col col-4">补充</div>
                </div>
                <div v-for="(item, index) in abnormalGoodsList" :key="index" class="abnormal-goods-body">
                  <div class="col col-1">
                    <div class="good-name">{{ item.itemName }} {{ item.itemSpec }}</div>
                    <div class="good-company">{{ item.itemManufacturer }}</div>
                    <div class="good-code">{{ item.itemCode }}</div>
                  </div>
                  <div class="col col-2 abnormal-type">
                    <Tooltip :content="item.abnormalType" max-width="200">
                      <div class="type-desc">{{ item.abnormalType }}</div>
                    </Tooltip>
                    
                  </div>
                  <div class="col col-3">
                    <div>
                      <span class="unit">{{item.abnormalNum}} {{ item.itemUnit }}</span>
                    </div>
                  </div>
                  <div class="col col-4">
                    <div>{{ item.abnormalRemark || '暂无'}}</div>
                  </div>
                </div>
              </div>
              <div v-else>暂无</div>
            </div>
            <div class="certificate">
              <div class="title">凭证</div>
              <div v-if="picList.length" class="content-imgs">
                <img v-for="(pic,index) in picList" :key="index" class="feedback-img" :src="pic" mode="aspectFill" alt="" @click="previewPic(pic)">
              </div>
              <div v-else>暂无</div>
            </div>
          </div>
        </div>
      </div>
    </div>    
  </div>
</template>
<script>
import Loading from '@/components/Loading.vue'
import {getConsigneeGoodsList, getSignReceiptList} from '@/common/service/reorderSign'
import { api } from '@/common/service/config'
export default {
  name: 'ReOrderSignDetail',
  components: {
    Loading
  },
  data() {
    return {
      keyword: '',
      abnormalGoodsList: [],
      pageNum: 1,
      pageSize: 1000, // 其实要求是不分页，但是后端接口必须得分页，所以这里设置成1000
      picList: [],
      epodId: '1898607245081714002',
      item: {},
      clientCode: '125191',
      isLoading: false
    }
  },
  created() {
    this.$emit('footer', false)
    this.epodId = this.$route.query?.epodId
    this.clientCode = this.$route.query?.clientCode
    this.getProductInfo()
    this.getSignForBaseInfo()
  },
  methods: {
    getSignForBaseInfo() {
      const {clientCode, epodId} = this
      getSignReceiptList({
        clientCode,
        epodIds: epodId
      }).then(res => {
        if (res.code === 200) {
          this.item = res.data.detail[0]
        }
      })
    },
    getProductInfo() {
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        condition: {
          epodId: this.epodId,
          itemType: 'abnormal',
          itemName: ''
        }
      }
      this.isLoading = true
      getConsigneeGoodsList(params).then(res => {
        this.isLoading = false
        if (res.code === 200) {
          if (res && res.data && Array.isArray(res.data.itemList) && res.data.itemList.length > 0) {
            this.abnormalGoodsList = res.data.itemList
          }
          if (this.pageNum === 1) {
            res.data?.abnormalFileIds && res.data.abnormalFileIds?.split(',')?.forEach(item => {
              this.picList.push(`/apis/rym-file/fileCenter/downloadImage?fileId=${item}`)
            })
          }
        }
      }).catch(error => {
        this.isLoading = false
        this.$message.error(error.data.message)
      })
    },
    copyCode(code) {
      this.$copyText(code).then(e => {
          this.$message.success('复制成功')
        },e => {
          this.$message.error('复制失败')
        }
      )
    },
    previewPic(pic) {
      const otherPics = this.picList.filter((d) => d !== pic)
      const images = [pic, ...otherPics]
      this.$viewerApi({
        images
      })
    },
    onPreview(signFileId) {
      // let url = '/apis/rym-file/fileCenter/downloadPdf?fileId=' + this.currentSignFile.signFileId
      let url = `/apis/rym-file/fileCenter/downloadPdf?fileId=${signFileId}`
      window.open(url, '_blank')
    },
    toSignPage() {
      this.$router.push('/electroncEvidence/index')
    }

  }
}

</script>
<style lang="less" scoped>
.sign-content {
  width: 1200px;
  margin: auto;
}
.sign-list-content {
      background-color: #fff;
      margin-top: 12px;
      padding: 24px;
      .company-title {
        color: rgba(0, 0, 0, 0.9);
        /* medium/16 */
        font-family: 'PingFang SC';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        margin-bottom: 4px;
      }
      .company-total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .c-t-l {
          display: flex;
          align-items: center;
          span {
            margin-right: 8px;
            color: rgba(0, 0, 0, 0.9);
            /* regular/14 */
            font-family: 'PingFang SC';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }
          .red {
            color: var(--error-7, #cb272d);
            /* medium/18 */
            font-family: 'PingFang SC';
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 144.444% */
          }
        }
        .c-t-r {
          color: rgba(0, 0, 0, 0.6);
          /* regular/14 */
          font-family: 'PingFang SC';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
          span {            
            margin-left: 24px;
          }
        }
      }
      .sign-list-content-bottom {
        margin-top: 24px;
        display: flex;
        .s-l-c-b-l {
          position: relative;
          width: 360px;
          height: 510px;
          border: 1px solid var(--gray-3, #eee);
          margin-right: 24px;
          .full-screen {
            position: absolute;
            right: 16px;
            top: 70px;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.6);
            width: 82px;
            height: 28px;
            display: flex;
            padding: 0px 8px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            cursor: pointer;
            span {
              color: var(--fontcolor-white-0, #fff);
              font-family: 'PingFang SC';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px; /* 116.667% */
              white-space: nowrap;
            }
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
        .s-l-c-b-r {
          flex: 1;
          .abnormal {
            margin-bottom: 24px;
            .title {
              color: rgba(0, 0, 0, 0.9);
              /* medium/16 */
              font-family: 'PingFang SC';
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
              margin-bottom: 4px;
            }
            .tip {
              color: rgba(0, 0, 0, 0.6);
              /* regular/12 */
              font-family: 'PingFang SC';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 166.667% */
              margin-bottom: 16px;
            }
            .abnormal-goods {
              margin-top: 16px;
              .abnormal-goods-header {
                display: flex;
                .col {
                  display: flex;
                  height: 40px;
                  padding: 0px 8px;
                  align-items: center;
                  background: var(--gray-1, #fafafa);
                  box-sizing: border-box;
                  color: rgba(0, 0, 0, 0.9);
                  /* regular/12 */
                  font-family: 'PingFang SC';
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px; /* 166.667% */
                  span.red {
                    color: #cb272d;
                  }
                  &.col-1 {
                    width: 268px;
                  }
                  &.col-2 {
                    width: 112px;
                  }
                  &.col-3 {
                    width: 112px;
                  }
                  &.col-4 {
                    flex: 1;
                  }
                  &.col-5 {
                    width: 48px;
                  }
                }
              }
              .abnormal-goods-body {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-bottom: 1px solid var(--gray-3, #eee);
                .col {
                  height: 80px;
                  padding: 8px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  font-size: 12px;
                  color: rgba(0, 0, 0, 0.9);
                  &.col-1 {
                    width: 268px;
                    div {
                      width: 100%;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      &.good-name {
                        color: rgba(0, 0, 0, 0.9);
                        font-family: 'PingFang SC';
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px; /* 166.667% */
                      }
                      &.good-company,
                      &.good-code {
                        color: rgba(0, 0, 0, 0.6);
                        /* regular/12 */
                        font-family: 'PingFang SC';
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                      }
                    }
                  }
                  &.col-2 {
                    width: 112px;
                    .abnormal-num {
                      width: 96px;
                    }
                  }
                  &.col-3 {
                    width: 112px;
                    .abnormal-num {
                      width: 76px;                       
                    }
                    .unit {
                      color: rgba(0, 0, 0, 0.9);
                      /* regular/12 */
                      font-family: 'PingFang SC';
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 20px; /* 166.667% */
                      margin-left: 8px;
                    }
                  }
                  &.col-4 {
                    flex: 1;
                    .abnormal-num {
                      width: 100%;
                    }
                  }
                  &.col-5 {
                    width: 48px;
                    .del-btn {
                      color: var(--hblue-6, #3853e2);
                      /* regular/12 */
                      font-family: 'PingFang SC';
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 20px; /* 166.667% */
                      cursor: pointer;
                    }
                  }
                }
                .abnormal-type {
                  width: 112px;
                  .type-desc {
                    -webkit-line-clamp: 3;
                    white-space: normal;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    word-break: break-all;
                    -webkit-box-orient: vertical;
                    cursor: pointer;
                  }
                }
              }
            }
          }
          .certificate {
            .title {
              color: rgba(0, 0, 0, 0.9);
              /* medium/16 */
              font-family: 'PingFang SC';
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
              margin-bottom: 4px;
            }
          }
        }
      }
    }
    .content-imgs {
      margin-top: 16px;
      gap: 16px;
      display: flex;
      width: 768px;
      flex-wrap: wrap;
      img {
        width: 120px;
        height: 120px;     
      }
    }
</style>
