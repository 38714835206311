import { get,post } from '@/common/utils'
import {api} from "@/common/service/config";
/**
 * 查询主数据客户列表
 */
export const getCustomMdmList = (data) => post({
	url:`${api.baseUrl}/consignee/registration/getCustomMdmList`,
    data,
})

/**
 * 收货备案列表
 */
export const getRegistrationList = (data) => post({
	url:`${api.baseUrl}/consignee/registration/getRegistrationList`,
    data,
})

/**
 * 签收单据分页列表
 */
export const getSignReceiptPage = (data) => post({
	url:`${api.baseUrl}/signreceipt/getSignReceiptPage`,
    data,
})


