import { render, staticRenderFns } from "./FrozenPopup.vue?vue&type=template&id=0fbf6bd3&scoped=true"
import script from "./FrozenPopup.vue?vue&type=script&lang=js"
export * from "./FrozenPopup.vue?vue&type=script&lang=js"
import style0 from "./FrozenPopup.vue?vue&type=style&index=0&id=0fbf6bd3&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_40df3e800382af9a7a59c1fe33ba5098/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fbf6bd3",
  null
  
)

export default component.exports