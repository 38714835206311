import { render, staticRenderFns } from "./goodsChange.vue?vue&type=template&id=70f74973&scoped=true"
import script from "./goodsChange.vue?vue&type=script&lang=js"
export * from "./goodsChange.vue?vue&type=script&lang=js"
import style0 from "./goodsChange.vue?vue&type=style&index=0&id=70f74973&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_40df3e800382af9a7a59c1fe33ba5098/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70f74973",
  null
  
)

export default component.exports