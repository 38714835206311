import { get, post } from '@/common/utils'
import { api } from '@/common/service/config'
/**
 * 签收品种详情
 */
export const getConsigneeGoodsList = (data) => post({
  url: `${api.baseUrl}/consignee/epod/getConsigneeGoodsList`,
  data
})

/**
* 签收中信息保存
*/
export const submitConsigneeBatch = (data) => post({
    url: `${api.baseUrl}/consignee/epod/submitConsigneeBatch`,
    data,
})
/**
 * 获取预览文件code
 */
export const getPreviewCode  = (data) => get({
    url: `/apis/rym-file/fileCenter/onlinePreview?fileId=${data.fileId}`,
    isLoading: true
})
/**
* 签收单据列表
*/
export const getSignReceiptList = (data) => post({
    url: `${api.baseUrl}/signreceipt/getSignReceiptList`,
    data,
})
