<template>
  <div class="price-outer">
    <!--客户异常状态显示 -->
    <div v-if="errorState" class="error-state">
      {{ errorState }}
    </div>
    <div v-else class="show-price" v-show="priceReady">
      <div class="activity-price">
        <span v-if="goodsItem.estimatePrice" class="prefix">折后约</span>
        <template v-if="redPrice">

          <template v-if="groupBuyActive && stepPrice > 0">
            <em class="cny">¥</em><em class="int">{{ stepPrice.split('.')[0] }}</em><em class="float">.{{ stepPrice.split('.')[1] }}</em><em class="flet">-</em>
            <em class="cny">¥</em><em class="int">{{ returnFloat(activityPrice,0.01).split('.')[0] }}</em><em class="float">.{{ returnFloat(activityPrice,0.01).split('.')[1] }}</em>
          </template>
          <template v-else>
            <em class="cny">¥</em><em class="int">{{ redPrice.split('.')[0] }}</em><em class="float">.{{ redPrice.split('.')[1] }}</em>
          </template>

        </template>
        <template v-else>
          <span class="no-price">未定价</span>
        </template>
      </div>
      <div v-if="activityPrice || goodsItem.estimatePrice" class="through-price">
        {{ originPrize ? '¥' + originPrize : '未定价' }}
      </div>
    </div>
  </div>
</template>

<script>
import { returnFloat } from '@/common/utils/format'
import { CONTROL_MSG, getAccountErr } from '@/common/utils'
export default {
  name: 'PriceArea',
  components: {},
  props: {
    goodsItem: {
      type: Object,
      default: () => ({})
    },
    isGiftGood: {
      type: Boolean
    },
    skipAccountCheck: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      message: '',
      returnFloat,
      stepPrice: 0
    }
  },
  computed: {
    // 客户状态异常信息
    errorState() {
      if (this.isGiftGood) {
        return ' '
      }
      if (this.skipAccountCheck) {
        return this.prdControl
      }
      const accountErr = getAccountErr(this.goodsItem)
      return accountErr || this.prdControl
    },
    isBeiExchangeGoods() {
      return this.$route.query?.activityType === '99'
    },
    // // 换购品价格
    // exchangePrice() {
    //   return returnFloat(this.goodsItem?.exchangeMarketing?.[0]?.exchangePrice)
    // },
    // 活动价
    activityPrice() {
      // 兼容加购弹窗数据结构
      if (this.goodsItem.activityPrice) {
        return this.goodsItem.activityPrice
      }
      // 是否是 复制类型的活动(润划算、近效期、拼团)
      // 如果是 拼团、润滑算、拼团 或者换购 显示活动价格
      if (this.isBeiExchangeGoods) {
        // 换购品价格不可能为空，添加判空 排除后端接口返回的BUG
        if (this.goodsItem?.exchangeMarketing?.[0]?.exchangePrice === null) {
          return ''
        }
        // 换购品活动价
        return this.goodsItem?.exchangeMarketing?.[0]?.exchangePrice
      }
      if ([2, 5, 6, 8].includes(Number(this.goodsItem.marketing?.activityType))) {
        // 活动价格不可能为空，添加判空 排除后端接口返回的BUG
        if (this.goodsItem?.marketing?.customerTypePrice?.[0]?.price === null) {
          return ''
        }
        // 活动价格
        return this.goodsItem?.marketing?.customerTypePrice?.[0]?.price || ''
      }
      return ''
    },
    // 商品原价
    originPrize() {
      // 兼容加购弹窗数据结构
      if (this.goodsItem.currentPrice) {
        return returnFloat(this.goodsItem.currentPrice)
      }
      const customerTypePrice = this.goodsItem?.customerTypePrice ?? []
      return returnFloat(customerTypePrice?.[0]?.price ?? '')
    },
    redPrice() {
      /*
       * V1.37
       *  有预估到手价，优先展示预估到手价
       *  如果没有预估到手价
       *     如果有 活动价 没有原价 返回活动价
       *     如果有活动价和原价 取最小值返回
       *     如果没有活动价 返回原价
       * */
      const estimatePrice = this.goodsItem?.estimatePrice ?? ''
      const originPrize = this.originPrize
      const activityPrice = this.activityPrice
      //  有预估到手价，优先展示预估到手价
      if (estimatePrice) {
        return returnFloat(estimatePrice, originPrize)
      }
      /**
       * 如果有 有活动价 和原价 取最小值返回
       * */
      if (activityPrice && originPrize) {
        return returnFloat(Math.min(Number(activityPrice), Number(originPrize)), originPrize)
      }
      // 如果有活动价，没有原价 返回活动价
      // if (activityPrice && !originPrize) {
      //   return returnFloat(activityPrice)
      // }
      // 如果没有活动价，返回原价
      return returnFloat(originPrize || activityPrice, originPrize)
    },
    prdControl() {
      // 控销价格显示开关
      const { reason, prdControlVisible } = this.goodsItem
      // 控销信息
      if (reason && !prdControlVisible) {
        return Reflect.get(CONTROL_MSG, this.goodsItem.reason) || ''
      }
      return ''
    },
    groupBuyActive() {
      return [6].includes(Number(this.goodsItem.marketing?.activityType))
    },
    priceReady() {
      // 如果当前商品存在 价格加载标记字段，则返回该字段的值，否则 不进行价格loading等待 返回true
      // priceReady 字段在搜索结果列表 获取预估到手价后 标记为价格加载完成 priceReady true
      if (Object.prototype.hasOwnProperty.call(this.goodsItem, 'priceReady')) {
        return this.goodsItem.priceReady
      }
      return true
    }
  },
  watch: {
    goodsItem: {
      handler(newVal) {
        const { minActivityPrice, marketing } = newVal
        if (minActivityPrice) {
          // 阶梯价格最小值
          this.stepPrice = returnFloat(newVal?.minActivityPrice, 0.01)
          // 阶梯价格最小值等于活动价 阶梯价格最小值置为0
          if (newVal?.minActivityPrice === this.activityPrice) {
            this.stepPrice = 0
          }
          return
        }
        if (marketing && marketing?.customerTypePrice[0]) {
          const { minActivityPrice, price } = marketing?.customerTypePrice[0]
          if (minActivityPrice !== price) {
            this.stepPrice = returnFloat(minActivityPrice, 0.01)
            return
          } else {
            this.stepPrice = 0
          }
          return
        }
      },
      deep: true,
      immediate: true

    }
  }
}
</script>

<style lang="less" scoped>
.price-outer {
  .show-price {
    height: 28px;
    overflow: hidden;
    .activity-price {
      vertical-align: middle;
      display: inline-block;
      height: 28px;
      white-space: nowrap;
      overflow: hidden;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #cb272d;
      margin-right: 8px;
      //text-overflow: ellipsis;
      max-width: 100%;
    }
    .through-price {
      display: inline-block;
      vertical-align: middle;
      height: 28px;
      text-decoration-line: line-through;
      text-decoration-color: #999;
      font-weight: 400;
      font-size: 14px;
      //line-height: 28px;
      color: rgba(0, 0, 0, 0.6);
      margin-top: 7px;
    }
  }
}

.error-state {
  color: var(--error-7, #cb272d);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.estimated-price {
  border-radius: 8px;
  background: #fbebe7;
  line-height: 24px;
  padding: 0 8px;
  color: #d4380d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;

  display: inline-block;
  height: 22px;
}

.activity-price span {
  font-size: 12px;
  line-height: 28px;
  vertical-align: middle;
}
em {
  font-style: normal;
}
.prefix {
  color: var(--error-7, #cb272d);
  margin-right: 4px;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.flet {
  color: var(--error-7, #cb272d);
  font-family: 'PingFang SC';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  padding: 0 3px;
}
.cny {
  color: var(--error-7, #cb272d);
  font-family: 'PingFang SC';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}
.int {
  color: var(--error-7, #cb272d);
  font-family: 'Arial';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.float {
  font-family: 'Arial';
  color: #cb272d;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.activity-price .no-price {
  font-size: 16px;
}
</style>
